<template>
  <div v-bind="$attrs">
    <div class="container">
      <div class="inner">
        <nav
          v-for="(nav, i) in subMenu"
          v-once
          :key="i"
          class="inner__nav inner__nav--hidden"
        >
          <div class="inner__wrap">
            <h3 class="inner__title">{{ nav.title }}</h3>
            <ul class="inner__list">
              <li
                v-for="link in nav.links"
                :key="link.name"
                class="inner__item"
              >
                <nuxt-link to="/" class="inner__link">{{
                  link.name
                }}</nuxt-link>
              </li>
            </ul>
          </div>
          <button
            type="button"
            class="inner__toggle"
            data-name="Скрыть"
            @click="toggleMenu"
          >
            {{ nav.buttonName }}
            <CIcon name="chevron-down" class="inner__chevron" />
          </button>
        </nav>
        <nav
          v-for="(nav, i) in subMenu"
          :key="i"
          class="inner__nav inner__nav--hidden"
        >
          <div class="inner__wrap">
            <h3 class="inner__title">{{ nav.title }}</h3>
            <ul class="inner__list">
              <li
                v-for="link in nav.links"
                :key="link.name"
                class="inner__item"
              >
                <nuxt-link to="/" class="inner__link">{{
                  link.name
                }}</nuxt-link>
              </li>
            </ul>
          </div>
          <button
            type="button"
            class="inner__toggle"
            data-name="Скрыть"
            @click="toggleMenu"
          >
            {{ nav.buttonName }}
            <CIcon name="chevron-down" class="inner__chevron" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import CIcon from '@/components/common/CIcon'

export default {
  name: 'HeaderDropdownMenu',

  components: {
    CIcon,
  },

  props: {
    subMenu: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    toggleMenu(e) {
      const block = 'inner__nav'
      const modifier = 'inner__nav--hidden'
      const parent = e.target.closest(`.${block}`)

      if (!parent.classList.contains(modifier)) {
        parent.classList.add(modifier)
      } else {
        document.querySelectorAll(`.${block}`).forEach((node) => {
          node.classList.add(modifier)
        })
        parent.classList.remove(modifier)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  padding: rem(28) 0;
  &__wrap {
    margin-bottom: rem(20);
  }
  &__nav {
    display: flex;
    flex-direction: column;
    max-width: rem(340);
    overflow: hidden;
    transition: max-width $default-transition, min-width $default-transition;
    &:not(:first-child) {
      margin: 0 0 0 rem(24);
      .inner__wrap {
        padding: 0 0 0 rem(26);
        border-left: rem(1) solid rgba(51, 64, 58, 0.3);
      }
      .inner__toggle {
        margin-left: rem(26);
      }
    }
    &--hidden {
      max-width: rem(170);
    }
    &:not(.inner__nav--hidden) {
      .inner__toggle {
        font-size: 0;
        &:before {
          font-size: rem(12.5);
          content: attr(data-name);
        }
      }
    }
  }
  &__title {
    margin-bottom: rem(8);
    color: $text-color;
    font-weight: 700;
    font-size: rem(15);
    letter-spacing: rem(0.45);
  }
  &__list {
    width: 100%;
    margin: 0;
    padding: 0;
    column-count: 2;
    list-style: none;
    @include base-width(340);
  }
  &__item {
    @include base-width(170);
  }
  &__link {
    display: inline-flex;
    margin-top: rem(9);
    color: $text-color;
    font-size: rem(13.5);
    text-decoration: none;
    transition: color $default-transition;
    &:hover {
      color: $color-green;
      text-decoration: underline;
    }
  }
  &__toggle {
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    margin-top: auto;
    color: $color-green;
    font-weight: 700;
    font-size: rem(12.5);
    transition: color $default-transition;
    &:hover {
      color: $dark-green2;
      text-decoration: underline;
      .inner__chevron {
        fill: $dark-green2;
      }
    }
  }
  &__chevron {
    margin: rem(1) 0 0 rem(4);
    transform: rotate(-90deg);
    transition: fill $default-transition;
    fill: $color-green;
    @include base-width(8);
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .container {
    max-width: unset;
    padding: 0 rem(13);
  }
  .inner {
    overflow-x: auto;
    &__nav {
      min-width: rem(340);
      &--hidden {
        min-width: rem(170);
      }
    }
  }
}
</style>
