<template>
  <!-- TODO: разнести по компонентам -->
  <div
    id="bottomLine"
    :class="['bottom-line', { 'is-active-mobile': isActiveMobileMenu }]"
  >
    <div :class="['line', { 'line--is-fixed': isFixed }]">
      <div class="container">
        <HeaderHamburger active white class="close" />

        <nav class="nav">
          <ul class="nav__list">
            <li v-for="(item, i) in menu" :key="i" class="nav__item">
              <nuxt-link class="nav__link" to="/dsadas">
                <CIcon
                  :name="item.icon"
                  :class="['nav__icon', `nav__icon--${item.icon}`]"
                />
                {{ item.name }}
                <CIcon
                  name="chevron-down"
                  class="nav__icon nav__icon--chevron"
                  @click.stop.prevent="toggleDropdownOnMobile"
                />
              </nuxt-link>
              <HeaderDropdownMenu
                :sub-menu="subMenu[item.code]"
                class="nav__dropdown"
              />
            </li>
          </ul>

          <ul class="nav__additional-list">
            <li class="nav__additional-item">
              <nuxt-link to="/" class="nav__additional-link">
                Цветы в коробке
              </nuxt-link>
            </li>
            <li class="nav__additional-item">
              <nuxt-link to="/" class="nav__additional-link">
                Цветы в корзине
              </nuxt-link>
            </li>
          </ul>

          <ul class="nav__actions-list">
            <li class="nav__actions-item">
              <input
                v-if="showFindInput"
                placeholder="Поиск"
                type="text"
                class="nav__actions-input"
                @blur="showFindInput = false"
              />
              <a
                v-else
                href="#"
                class="nav__actions-link"
                @click.prevent.stop="showFindInput = true"
              >
                <CIcon name="search" class="nav__actions-icon" />
                Поиск
              </a>
            </li>
            <li class="nav__actions-item">
              <nuxt-link to="/" class="nav__actions-link"> Акции </nuxt-link>
            </li>
          </ul>

          <button type="button" class="cart">
            <CIcon name="cart" class="cart__icon" />
            <span class="cart__price">0 руб.</span>
          </button>
        </nav>

        <SignIn v-if="isActiveMobileMenu" invert />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { MENU, SUB_MENU } from '@/components/Header/HeaderModules/headerUtils'

import SignIn from '@/components/Header/HeaderModules/HeaderTopLine/SignIn'
import HeaderDropdownMenu from '@/components/Header/HeaderModules/HeaderDropdownMenu'
import HeaderHamburger from '@/components/Header/HeaderModules/HeaderHamburger'

import CIcon from '@/components/common/CIcon'

export default {
  name: 'HeaderBottomLine',

  components: {
    SignIn,
    HeaderDropdownMenu,
    HeaderHamburger,
    CIcon,
  },

  data() {
    return {
      isFixed: false,

      showFindInput: false,
    }
  },

  computed: {
    ...mapState(['isActiveMobileMenu']),
  },

  created() {
    this.menu = MENU
    this.subMenu = SUB_MENU
  },

  mounted() {
    this.makeSticky()
    document.addEventListener('click', this.clickOutside)
  },

  beforeDestroy() {
    this.unstick()
    document.removeEventListener('click', this.clickOutside)
  },

  methods: {
    ...mapActions(['toggleMobileMenu']),
    callback([entrie], observer) {
      this.isFixed = !entrie.isIntersecting
    },

    clickOutside(e) {
      this.clickOutsideInput(e)
      this.clickOutsideMobileMenu(e)
    },

    clickOutsideMobileMenu(e) {
      if (e.target?.closest('.line, .hamburger')) return
      this.toggleMobileMenu({ value: false })
    },

    clickOutsideInput(e) {
      if (e.target?.closest('.nav__actions-input')) return
      this.showFindInput = false
    },

    makeSticky() {
      if (!this.$screen.large) return
      this.observer = new IntersectionObserver(this.callback, { threshold: 1 })
      this.observer.observe(document.getElementById('bottomLine'))
    },
    unstick() {
      if (!this.$screen.large) return
      this.observer.unobserve(document.getElementById('bottomLine'))
    },

    toggleDropdownOnMobile(e) {
      const parentNodesClassName = '.nav__item'
      const className = 'is-active-dropdown'
      const element = e.target.closest(parentNodesClassName)

      if (element.classList.contains(className)) {
        element.classList.remove(className)
      } else {
        document.querySelectorAll(parentNodesClassName).forEach((el) => {
          el.classList.remove(className)
        })
        element.classList.add(className)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin default-link($bgc) {
  display: inline-flex;
  align-items: center;
  min-height: rem(45);
  max-height: rem(45);
  padding: 0 rem(13);
  color: #fff;
  font-weight: 700;
  font-size: rem(13.5);
  text-decoration: none;
  background-color: $bgc;
  border-right: rem(1) solid $color-green;
  transition: color $default-transition, background-color $default-transition,
    border-color $default-transition;
}

.cart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity $default-transition, visibility $default-transition;
  &__icon {
    margin: 0 rem(8) 0 0;
    fill: #fff;
    @include base-width(22);
  }
  &__price {
    display: inline-flex;
    margin-left: rem(12);
    padding: rem(4) rem(9);
    color: #fff;
    font-weight: 600;
    font-size: rem(11);
    text-transform: uppercase;
    background-color: $color-pink;
    border-radius: rem(3);
  }
}

@mixin hover-styles() {
  .nav__dropdown {
    visibility: visible;
    opacity: 1;
  }
  .nav__link {
    color: $text-color;
    background-color: #fff;
    border-color: #fff;
    .nav__icon {
      fill: $text-color;
    }
    .nav__icon--chevron {
      transform: rotate(-180deg);
    }
  }
}

.close {
  position: absolute;
  top: rem(13);
  right: rem(9);
  margin: 0;
}

.bottom-line {
  min-height: rem(45);
}

.line {
  position: relative;
  height: rem(45);
  background-color: $color-green;
  &--is-fixed {
    position: fixed;
    top: -1px;
    right: 0;
    left: 0;
    z-index: 100;
  }
}

.nav {
  position: relative;
  display: flex;
  &__list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  &__link {
    @include default-link($dark-green);
  }
  &__item {
    &:hover {
      @media only screen and (min-width: $breakpoint-desktop + 1) {
        @include hover-styles();
      }
    }
    &.is-active-dropdown {
      @include hover-styles();
    }
  }
  &__icon {
    transition: fill $default-transition, transform $default-transition;
    fill: #fff;
    &--flower {
      margin: 0 rem(3) 0 0;
      @include base-width(26);
    }
    &--calendar {
      margin: rem(-2) rem(8) 0 0;
      @include base-width(20);
    }
    &--rose {
      margin: 0 rem(4) 0 0;
      @include base-width(24);
    }
    &--gift {
      margin: rem(-2) rem(8) 0 0;
      @include base-width(20);
    }
    &--chevron {
      margin: 0 0 0 rem(6);
      @include base-width(6);
    }
  }
  &__additional {
    &-list {
      display: flex;
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &-link {
      @include default-link($color-green);
      &:hover {
        color: $text-color;
        background-color: #fff;
        border-color: #fff;
      }
    }
  }
  &__actions {
    &-list {
      display: flex;
      margin: 0 0 0 auto;
      padding: 0;
      list-style: none;
      visibility: visible;
      opacity: 1;
      transition: opacity $default-transition, visibility $default-transition;
    }
    &-link {
      @include default-link($color-green);
      &:hover {
        color: $text-color;
        background-color: #fff;
        border-color: #fff;
        .nav__actions-icon {
          fill: $text-color;
        }
      }
    }
    &-input {
      width: 100%;
      max-width: rem(160);
      height: rem(35);
      margin: auto 0;
      padding: 0 0 0 rem(10);
      font-size: rem(13.5);
      border: rem(1) solid $color-gray;
      border-radius: rem(2);
      @include placeholder {
        color: $text-color;
        font-size: rem(13.5);
      }
    }
    &-item {
      display: inline-flex;
      &:last-child {
        margin: 0 0 0 rem(1);
      }
    }
    &-icon {
      margin: 0 rem(8) 0 0;
      transition: fill $default-transition;
      fill: rgba(255, 255, 255, 0.5);
      @include base-width(16);
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) rem(4.5) rem(8) rem(27) 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition, visibility $default-transition;
  }
}

.line.line--is-fixed {
  .cart {
    visibility: visible;
    opacity: 1;
  }
  .nav__actions-list {
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-width: $breakpoint-desktop) {
  .line {
    padding: rem(36) 0 0 0;
  }
  .bottom-line {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity $default-transition, visibility $default-transition;
    .line,
    .container {
      max-width: 0;
      height: 100%;
      overflow: hidden;
      transition: max-width $default-transition;
    }
    .container {
      display: flex;
      flex-direction: column;
      height: unset;
      max-height: 100%;
      overflow-y: auto;
    }
    &.is-active-mobile {
      visibility: visible;
      opacity: 1;
      .line,
      .container {
        max-width: rem(320);
      }
    }
  }
  .nav {
    flex-direction: column;
    margin: 0 rem(-9);
    &__list,
    &__additional-list,
    &__actions-list {
      flex-direction: column;
      width: 100%;
      &::after {
        order: 3;
        width: 100%;
        height: rem(1);
        background-color: rgba($dark-green, 0.5);
        content: '';
      }
    }
    &__link,
    &__additional-link,
    &__actions-link {
      width: 100%;
      background-color: $color-green;
    }
    &__actions-item {
      &:last-child {
        order: 1;
      }
      &:first-child {
        order: 2;
      }
    }
    &__actions-input {
      max-width: 100%;
      margin: 0 rem(13);
    }
    &__icon--chevron {
      margin-left: auto;
      @include base-width(12);
    }

    &__dropdown {
      position: relative;
      top: 0;
      max-height: 0;
      overflow: hidden;
      visibility: visible;
      opacity: 1;
      transition: max-height $default-transition;
    }

    &__item {
      &.is-active-dropdown {
        .nav__dropdown {
          max-height: 999px;
        }
      }
    }
  }
}
</style>
