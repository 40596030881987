<template>
  <button
    v-bind="$attrs"
    :class="[
      'hamburger',
      'hamburger--squeeze',
      { 'is-active': active, 'is-white': white },
    ]"
    aria-label="hamburger"
    type="button"
    @click="toggleMobileMenu({ value: !active })"
  >
    <span class="hamburger__box">
      <span class="hamburger__inner"></span>
    </span>
  </button>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'HeaderHamburger',

  props: {
    active: Boolean,
    white: Boolean,
  },

  computed: {
    ...mapState(['isActiveMobileMenu']),
  },

  methods: {
    ...mapActions(['toggleMobileMenu']),
  },
}
</script>

<style lang="scss" scoped>
.hamburger {
  display: none;
  order: 2;
  margin: rem(4) 0 0 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;

  @media only screen and (max-width: $breakpoint-desktop) {
    display: inline-flex;
  }
  &.is-white.is-active {
    .hamburger__inner,
    .hamburger__inner::before,
    .hamburger__inner::after {
      background-color: #fff;
    }
  }
}
.hamburger.is-active .hamburger__inner,
.hamburger.is-active .hamburger__inner::before,
.hamburger.is-active .hamburger__inner::after {
  background-color: $color-green;
}

.hamburger__box {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 18px;
}

.hamburger__inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}
.hamburger__inner,
.hamburger__inner::before,
.hamburger__inner::after {
  position: absolute;
  width: 26px;
  height: 2px;
  background-color: $color-green;
  border-radius: 2px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}
.hamburger__inner::before,
.hamburger__inner::after {
  display: block;
  content: '';
}
.hamburger__inner::before {
  top: -8px;
}
.hamburger__inner::after {
  bottom: -8px;
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger__inner {
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.075s;
}
.hamburger--squeeze .hamburger__inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger__inner::after {
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger__inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger__inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger__inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
